<template>
  <div class="column pt-0">
    <div class="has-background-white p-4">
      <h5 class="mb-4 has-text-weight-medium">ЗАРЕГИСТРИРОВАТЬ ФИЛИАЛ</h5>
      <form
        action=""
        @submit.prevent="createBranch"
      >
        <p class="mb-3">Введите данные</p>

        <b-field label="Компания">
          <b-select
            placeholder="Выберите компанию"
            v-model="company"
            required
          >
            <option
              v-for="option in companies"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Телеграм канал">
          <b-input
            type="text"
            v-model.trim="telegram_channel"
            placeholder="ID телеграм канала"
            required
          />
        </b-field>
        <b-field
          class="mt-4"
          label="Наименование филиала"

        >
          <b-input
            type="text"
            v-model.trim="branch_name"
            placeholder="Наименование филиала"
            required
          />
        </b-field>
        <button class="button is-info mt-5">Зарегистрировать</button>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      companies: [],
      branch_name: "",
      company: null,
      telegram_channel: null,
    }
  },
  mounted() {
    this.fetchCompanies()
  },
  methods: {
    async fetchCompanies() {
      try {
        const { data } = await this.$axios.get(`admin/companies`)
        this.companies = data?.data || []
        console.log(this.companies)
      } catch (e) {
        throw new Error(e)
      }
    },
    async createBranch() {
      try {
        const { data } = await this.$axios.post(`admin/branches`, {
          name: this.branch_name,
          company_id: this.company,
          telegram_chanel: this.telegram_channel,
        })
        if (data?.code === 201) this.$router.push("/filials-list");
      } catch (err) {
        console.log(err)
        throw new Error(err)
      }
    },
  },
}
</script>
